<ion-card *ngFor="let item of customFields; let i = index" class="outline-card">
  <ion-item class="padded-row">
    <ion-input
      [(ngModel)]="item.label"
      [placeholder]="placeholder || 'Ex: what topics would you like to cover?'"
      style="font-weight: 500"
    >
    </ion-input>
    <ion-select interface="popover" [(ngModel)]="item.type" slot="end">
      <ion-select-option *ngFor="let type of customFieldTypes" [value]="type.key">
        {{ type.displayName }}
      </ion-select-option>
    </ion-select>
    <ion-select
      interface="popover"
      *ngIf="item.type === 'user-attribute' && userAttributes.length"
      [(ngModel)]="item.fieldKey"
      slot="end"
    >
      <ion-select-option *ngFor="let attribute of userAttributes" [value]="attribute">
        {{ attribute }}
      </ion-select-option>
    </ion-select>
    <ion-label slot="end" *ngIf="item.type === 'user-attribute' && !userAttributes.length">
      <span *ngIf="item.fieldKey">{{ item.fieldKey }}</span>
      <i *ngIf="!item.fieldKey">None</i>
    </ion-label>
    <ion-button
      color="dark"
      fill="clear"
      slot="end"
      (click)="item.required = !item.required"
      class="item-accessory-button button-dark-border"
      *ngIf="!item.required && item.type !== 'user-attribute' && item.type !== 'text'"
      ngxTippy
      data-tippy-content="Response is optional. Click to make required."
    >
      <ion-icon name="help-circle-outline"> </ion-icon>
    </ion-button>
    <ion-button
      color="dark"
      slot="end"
      (click)="item.required = !item.required"
      class="item-accessory-button button-dark-border"
      *ngIf="item.required && item.type !== 'user-attribute'"
      ngxTippy
      data-tippy-content="Response is required. Click to make optional."
    >
      <ion-icon name="checkmark-circle"> </ion-icon>
    </ion-button>
    <ion-button
      slot="end"
      color="dark"
      (click)="item.public = !item.public"
      class="item-accessory-button button-dark-border"
      *ngIf="showPublicOption && item.public"
      ngxTippy
      data-tippy-content="Response is publicly visible. Click to make private."
    >
      <ion-icon name="eye-outline"></ion-icon>
    </ion-button>
    <ion-button
      color="dark"
      fill="clear"
      slot="end"
      (click)="item.public = !item.public"
      class="item-accessory-button button-dark-border"
      *ngIf="showPublicOption && !item.public"
      ngxTippy
      data-tippy-content="Response is only visible to support reps. Click to make public."
    >
      <ion-icon name="eye-off-outline"></ion-icon>
    </ion-button>
    <ion-button
      slot="end"
      [fill]="getConditionsForField(item)?.length ? 'solid' : 'clear'"
      color="dark"
      (click)="toggleConditionsShown(i)"
      class="item-accessory-button button-dark-border"
      ngxTippy
      data-tippy-content="Toggle conditions when field will appear"
      *ngIf="showBranchingInput"
    >
      <ion-icon name="filter-outline"></ion-icon>
    </ion-button>
    <ion-button
      *ngIf="customFields.length > 1"
      slot="end"
      fill="clear"
      color="dark"
      (click)="reorderField(i)"
      class="item-accessory-button"
      ngxTippy
      data-tippy-content="Reorder"
    >
      <ion-icon name="swap-vertical-outline"></ion-icon>
    </ion-button>
    <ion-button slot="end" color="dark" fill="clear" (click)="showCustomFieldOptions(i)" class="item-accessory-button">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-item *ngIf="showDescription" style="overflow: visible">
    <ion-input *ngIf="item.type !== 'text'" [(ngModel)]="item.description" [placeholder]="'Description...'"></ion-input>
    <app-tiptap-editor
      *ngIf="item.type === 'text'"
      #editorContainer
      [(ngModel)]="item.description"
      [placeholder]="'Description...'"
      [enableLinkEditing]="true"
      class="description-editor"
    ></app-tiptap-editor>
  </ion-item>
  <div *ngIf="item.type === 'single-select' || item.type === 'multi-select'" class="dropdown-option-container">
    <div class="dropdown-option-label" style="display: flex; align-items: center; justify-content: space-between">
      <div>
        <ion-icon name="list-outline"></ion-icon>
        List of options:
      </div>

      <!-- Toggle to switch between AND and OR conditions -->
      <div class="flexed" style="align-items: center">
        <ion-label style="margin-right: 5px">Load from external source</ion-label>
        <ion-checkbox
          [checked]="item.externalSelect !== null && item.externalSelect !== undefined"
          (ionChange)="toggleExternalSelect(item)"
        ></ion-checkbox>
      </div>
    </div>
    @if (isExternalSelectField(item)) {
      <div style="margin-bottom: 10px">
        <ion-chip
          mode="ios"
          class="select-chip select-outline"
          [outline]="true"
          (click)="showExternalSelectFunctions(item)"
        >
          <ion-label>
            {{ item.externalSelect || 'Select a custom function' }}
            <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
          </ion-label>
        </ion-chip>
      </div>
    }
    @if (!isExternalSelectField(item)) {
      <ion-list class="no-padding">
        <ion-reorder-group (ionItemReorder)="onReorder($event, item)" [disabled]="false" style="width: 100%">
          <div
            *ngFor="let opt of item.options; let j = index"
            class="dropdown-option-item flex-space-between"
            style="align-items: center; margin: 0 10px"
          >
            <ion-label style="margin: 0 10px">{{ opt }}</ion-label>
            <div slot="end" class="flexed">
              <ion-buttons>
                <ion-button (click)="deleteCustomFieldOption(i, j)" ngxTippy data-tippy-content="Remove option">
                  <ion-icon name="close-circle"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-reorder slot="end"> </ion-reorder>
            </div>
          </div>
        </ion-reorder-group>
      </ion-list>
      <ion-input
        placeholder="Add an option and hit enter"
        [(ngModel)]="dropdownOptionFields[i]"
        (keyup.enter)="addCustomFieldOption(i, dropdownOptionFields[i])"
        class="dropdown-option-input"
      >
      </ion-input>
    }
  </div>

  <ng-container *appVar="getConditionsForField(item) as conditions">
    <div *ngIf="showBranchingInput && conditions?.length" class="dropdown-option-container">
      <div class="dropdown-option-label">
        <div class="flex-space-between" style="align-items: center">
          <div>
            <ion-icon name="filter-outline"></ion-icon>
            Show this field only if:
          </div>
          <!-- Toggle to switch between AND and OR conditions -->
          <div class="flexed" style="align-items: center" *ngIf="conditions?.length > 1">
            AND
            <ion-toggle
              (ionChange)="toggleConditionsType(item)"
              [checked]="getConditionsType(item) !== 'and'"
            ></ion-toggle>
            OR
          </div>
        </div>

        <div *ngFor="let condition of conditions; let j = index" class="conditional-input">
          <div style="flex: 1; display: flex">
            <ion-select
              interface="popover"
              slot="start"
              (ionChange)="onSimpleConditionFieldChange($event, condition)"
              [(ngModel)]="condition.leftValueFrom"
              [compareWith]="compareFn"
            >
              <ion-select-option [value]="null">Select field</ion-select-option>
              <ion-select-option *ngFor="let field of getConditionalFieldOptions(i)" [value]="getFieldIdPath(field.id)">
                {{ field.label }}
              </ion-select-option>
            </ion-select>

            <ion-select interface="popover" slot="end" [(ngModel)]="condition.operator" [compareWith]="compareFn">
              <ion-select-option value="equals" *ngIf="getFieldType(condition.leftValueFrom) !== 'multi-select'"
                >equals</ion-select-option
              >
              <ion-select-option value="notEquals" *ngIf="getFieldType(condition.leftValueFrom) !== 'multi-select'"
                >does not equal</ion-select-option
              >
              <ion-select-option value="contains" *ngIf="getFieldType(condition.leftValueFrom) === 'multi-select'"
                >contains</ion-select-option
              >
              <ion-select-option value="notContains" *ngIf="getFieldType(condition.leftValueFrom) === 'multi-select'"
                >does not contain</ion-select-option
              >
              <ion-select-option value="isNotNull">exists</ion-select-option>
              <ion-select-option value="isNull">does not exist</ion-select-option>
            </ion-select>

            <ng-container *ngIf="showRightValueInput(condition)" [ngSwitch]="getFieldType(condition.leftValueFrom)">
              <ion-chip
                *ngSwitchCase="'single-select'"
                mode="ios"
                class="select-chip select-outline"
                [outline]="true"
                (click)="showExternalSelectOptions(condition.leftValueFrom, condition)"
              >
                <ion-label>
                  {{ getExternalSelectFieldLabel(condition.leftValueFrom, condition.rightValue) }}
                  <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
                </ion-label>
              </ion-chip>

              <div *ngSwitchCase="'multi-select'" style="display: flex; flex-wrap: wrap; align-items: center">
                <ion-chip
                  mode="ios"
                  color="primary"
                  class="chip tag-chip"
                  *ngFor="let value of condition.rightValue"
                  (click)="removeMultiSelectOptionFromCondition(condition, value)"
                >
                  <ion-label> {{ getExternalSelectFieldLabel(condition.leftValueFrom, value) }} </ion-label>
                </ion-chip>
                <ion-chip
                  mode="ios"
                  [outline]="true"
                  class="chip"
                  (click)="showExternalSelectOptions(condition.leftValueFrom, condition)"
                >
                  <ion-label>
                    <span>Add...</span>
                  </ion-label>
                </ion-chip>
              </div>

              <ion-select
                interface="popover"
                slot="end"
                *ngSwitchCase="'checkboxes'"
                [(ngModel)]="condition.rightValue"
                multiple="true"
                [compareWith]="compareFn"
                class="select-outline"
              >
                <ion-select-option [value]="true">True</ion-select-option>
                <ion-select-option [value]="false">False</ion-select-option>
              </ion-select>
              <ion-input
                *ngSwitchDefault
                slot="end"
                [(ngModel)]="condition.rightValue"
                placeholder="Enter value"
              ></ion-input>
            </ng-container>

            <ion-button fill="clear" color="dark" (click)="removeSimpleCondition(i, j)">
              <ion-icon name="close-circle"></ion-icon>
            </ion-button>
          </div>
        </div>

        <ion-button class="btn-small" fill="clear" color="dark" (click)="addSimpleCondition(i)">
          <ion-icon name="add-outline"></ion-icon> Add another condition
        </ion-button>
      </div>
    </div>
  </ng-container>
</ion-card>

<ion-item>
  <ion-button color="light" (click)="addCustomField()">
    <ion-icon name="add"></ion-icon>
    {{ addCustomFieldButtonLabel }}
  </ion-button>
</ion-item>
